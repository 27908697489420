fonts.css
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
}

.ql-font-centrazone,
.ql-font span[data-value="centrazone"]::before {
    font-family: "Centrazone", sans-serif;
}

.ql-font-montserrat,
.ql-font span[data-value="montserrat"]::before {
    font-family: "Montserrat", sans-serif;
}

.ql-font-montserratarm-bold,
.ql-font span[data-value="montserratarm-bold"]::before {
    font-family: 'Montserratarm Bold', sans-serif;
}

.ql-font-montserratarm-light,
.ql-font span[data-value="montserratarm-light"]::before {
    font-family: 'Montserratarm Light', sans-serif;
}

.ql-font-montserratarm-medium,
.ql-font span[data-value="montserratarm-medium"]::before {
    font-family: 'Montserratarm Medium', sans-serif;
}

.ql-font-montserratarm-regular,
.ql-font span[data-value="montserratarm-regular"]::before {
    font-family: 'Montserratarm Regular', sans-serif;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
    content: '9';
    font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='10px']::before {
    content: '10';
    font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='11px']::before {
    content: '11';
    font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='12px']::before {
    content: '12';
    font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='14px']::before {
    content: '14';
    font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='16px']::before {
    content: '16';
    font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='18px']::before {
    content: '18';
    font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='20px']::before {
    content: '20';
    font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='22px']::before {
    content: '22';
    font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='24px']::before {
    content: '24';
    font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='26px']::before {
    content: '26';
    font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='28px']::before {
    content: '28';
    font-size: 28px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='32px']::before {
    content: '32';
    font-size: 32px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='36px']::before {
    content: '36';
    font-size: 36px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='40px']::before {
    content: '40';
    font-size: 40px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='48px']::before {
    content: '48';
    font-size: 48px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='56px']::before {
    content: '56';
    font-size: 56px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='64px']::before {
    content: '64';
    font-size: 64px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='72px']::before {
    content: '72';
    font-size: 72px !important;
}

.ql-size-8px {
    font-size: 8px;
}
.ql-size-9px {
    font-size: 9px;
}
.ql-size-10px {
    font-size: 10px;
}
.ql-size-11px {
    font-size: 11px;
}
.ql-size-12px {
    font-size: 12px;
}
.ql-size-14px {
    font-size: 14px;
}
.ql-size-16px {
    font-size: 16px;
}
.ql-size-18px {
    font-size: 18px;
}
.ql-size-20px {
    font-size: 20px;
}
.ql-size-22px {
    font-size: 22px;
}
.ql-size-24px {
    font-size: 24px;
}
.ql-size-26px {
    font-size: 26px;
}
.ql-size-28px {
    font-size: 28px;
}
.ql-size-32px {
    font-size: 32px;
}
.ql-size-36px {
    font-size: 36px;
}
.ql-size-40px {
    font-size: 40px;
}
.ql-size-48px {
    font-size: 48px;
}
.ql-size-56px {
    font-size: 56px;
}
.ql-size-64px {
    font-size: 64px;
}
.ql-size-72px {
    font-size: 72px;
}


.line-dotted {
    border-style: dotted;
}
.line-solid {
    border-style: solid;
}
.line-double {
    border-style: double;
}

.ql-snow .ql-picker.ql-box .ql-picker-label::before,
.ql-snow .ql-picker.ql-box .ql-picker-item::before {
    content: 'None';
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value='solid']::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value='solid']::before {
    content: 'Solid';
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value='double']::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value='double']::before {
    content: 'Double';
}

.ql-snow .ql-picker.ql-box .ql-picker-label[data-value='dotted']::before,
.ql-snow .ql-picker.ql-box .ql-picker-item[data-value='dotted']::before {
    content: 'Dotted';
}

.ql-snow span.ql-picker.ql-box {
    width: 8em;
}
/* Default Colors */
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#000000"]::before { background-color: #000000; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#e60000"]::before { background-color: #e60000; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ff9900"]::before { background-color: #ff9900; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffff00"]::before { background-color: #ffff00; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#008a00"]::before { background-color: #008a00; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#0066cc"]::before { background-color: #0066cc; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#9933ff"]::before { background-color: #9933ff; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffffff"]::before { background-color: #ffffff; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#3d1466"]::before { background-color: #3d1466; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#002966"]::before { background-color: #002966; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#003700"]::before { background-color: #003700; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#666600"]::before { background-color: #666600; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#663d00"]::before { background-color: #663d00; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#5c0000"]::before { background-color: #5c0000; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#444444"]::before { background-color: #444444; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#6b24b2"]::before { background-color: #6b24b2; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#0047b2"]::before { background-color: #0047b2; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#006100"]::before { background-color: #006100; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#b2b200"]::before { background-color: #b2b200; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#b26b00"]::before { background-color: #b26b00; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#a10000"]::before { background-color: #a10000; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#888888"]::before { background-color: #888888; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#c285ff"]::before { background-color: #c285ff; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#66a3e0"]::before { background-color: #66a3e0; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#66b966"]::before { background-color: #66b966; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffff66"]::before { background-color: #ffff66; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffc266"]::before { background-color: #ffc266; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#f06666"]::before { background-color: #f06666; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#bbbbbb"]::before { background-color: #bbbbbb; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ebd6ff"]::before { background-color: #ebd6ff; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#cce0f5"]::before { background-color: #cce0f5; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#cce8cc"]::before { background-color: #cce8cc; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffffcc"]::before { background-color: #ffffcc; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#ffebcc"]::before { background-color: #ffebcc; }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="#facccc"]::before { background-color: #facccc; }
/* Add remaining default colors as needed */

/* Custom Colors */
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(42, 95, 64, 1)"]::before { background-color: rgba(42, 95, 64, 1); }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(133, 45, 35, 1)"]::before { background-color: rgba(133, 45, 35, 1); }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(249, 238, 225, 0.5)"]::before { background-color: rgba(249, 238, 225, 0.5); }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(255, 255, 255, 1)"]::before { background-color: rgba(255, 255, 255, 1); }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(41, 24, 24, 0.5)"]::before { background-color: rgba(41, 24, 24, 0.5); }
.ql-snow .ql-picker.ql-color .ql-picker-item[data-value="rgba(41, 24, 24, 1)"]::before { background-color: rgba(41, 24, 24, 1); }
/* Add remaining custom colors as needed */


.ql-snow .ql-lineheight .ql-picker-item[data-value='8px']::before {
    content: '8';
    line-height: 8px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='9px']::before {
    content: '9';
    line-height: 9px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='10px']::before {
    content: '10';
    line-height: 10px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='11px']::before {
    content: '11';
    line-height: 11px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='12px']::before {
    content: '12';
    line-height: 12px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='14px']::before {
    content: '14';
    line-height: 14px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='16px']::before {
    content: '16';
    line-height: 16px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='18px']::before {
    content: '18';
    line-height: 18px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='20px']::before {
    content: '20';
    line-height: 20px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='22px']::before {
    content: '22';
    line-height: 22px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='24px']::before {
    content: '24';
    line-height: 24px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='26px']::before {
    content: '26';
    line-height: 26px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='28px']::before {
    content: '28';
    line-height: 28px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='32px']::before {
    content: '32';
    line-height: 32px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='36px']::before {
    content: '36';
    line-height: 36px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='110px']::before {
    content: '110';
    line-height: 110px !important;
}

.ql-snow .ql-lineheight .ql-picker-item[data-value='176px']::before {
    content: '176';
    line-height: 176px !important;
}


.ql-lineheight-8px {
    line-height: 8px;
}
.ql-lineheight-9px {
    line-height: 9px;
}
.ql-lineheight-10px {
    line-height: 10px;
}
.ql-lineheight-11px {
    line-height: 11px;
}
.ql-lineheight-12px {
    line-height: 12px;
}
.ql-lineheight-14px {
    line-height: 14px;
}
.ql-lineheight-16px {
    line-height: 16px;
}
.ql-lineheight-18px {
    line-height: 18px;
}
.ql-lineheight-20px {
    line-height: 20px;
}
.ql-lineheight-22px {
    line-height: 22px;
}
.ql-lineheight-24px {
    line-height: 24px;
}
.ql-lineheight-26px {
    line-height: 26px;
}
.ql-lineheight-28px {
    line-height: 28px;
}
.ql-lineheight-32px {
    line-height: 32px;
}
.ql-lineheight-36px {
    line-height: 36px;
}
.ql-lineheight-110px {
    line-height: 110px;
}
.ql-lineheight-176px {
    line-height: 176px;
}
