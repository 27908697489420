@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: 'Montserratarm Bold';
  src: url('../public/fonts/Montserratarm-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserratarm Light';
  src: url('../public/fonts/Montserratarm-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserratarm Medium';
  src: url('../public/fonts/Montserratarm-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserratarm Regular';
  src: url('../public/fonts/Montserratarm-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Centrazone';
  src: url('../public/fonts/centrasone.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #FFFCFC;
}

em {
  font-style: italic;
}
strong {
  font-weight: bold;
}

:root {
  --height: 100px;
  --width: 200px;
  --secondarygreen: rgb(36, 159, 93, 1);
  --defaultwhite: rgba(255, 255, 255, 1);
  --primary-60: rgba(15, 98, 254, 1);
  --defaultalert: rgba(218, 30, 40, 1);
  --coolgray-30: rgba(193, 199, 205, 1);
  --coolgray-60: rgba(105, 112, 119, 1);
  --coolgray-90: rgba(33, 39, 42, 1);
  --coolgray-40: rgba(162, 169, 176, 1);
  --coolgray-10: rgba(242, 244, 248, 1);
  --primary-90: rgba(0, 29, 108, 1);
  --text-1-font-family: 'Montserrat-SemiBold', Helvetica;
  --text-1-font-weight: 600;
  --text-1-font-size: 24px;
  --text-1-letter-spacing: 0.5px;
  --text-1-line-height: 150%;
  --text-1-font-style: normal;
  --text-3-font-family: 'Montserrat-Regular', Helvetica;
  --text-3-font-weight: 400;
  --text-3-font-size: 18px;
  --text-3-letter-spacing: 0px;
  --text-3-line-height: 139.9999976158142%;
  --text-3-font-style: normal;
  --button-l-font-family: 'Roboto-Medium', Helvetica;
  --button-l-font-weight: 500;
  --button-l-font-size: 20px;
  --button-l-letter-spacing: 0.5px;
  --button-l-line-height: 100%;
  --button-l-font-style: normal;
  --button-m-font-family: 'Roboto-Medium', Helvetica;
  --button-m-font-weight: 500;
  --button-m-font-size: 16px;
  --button-m-letter-spacing: 0.5px;
  --button-m-line-height: 100%;
  --button-m-font-style: normal;
  --button-s-font-family: 'Roboto-Medium', Helvetica;
  --button-s-font-weight: 500;
  --button-s-font-size: 14px;
  --button-s-letter-spacing: 0.5px;
  --button-s-line-height: 100%;
  --button-s-font-style: normal;
  --body-m-font-family: 'Roboto-Regular', Helvetica;
  --body-m-font-weight: 400;
  --body-m-font-size: 16px;
  --body-m-letter-spacing: 0px;
  --body-m-line-height: 139.9999976158142%;
  --body-m-font-style: normal;
  --variable-collection-primary-black-100: rgba(41, 24, 24, 1);

  --variable-collection-primary-black-50: rgba(41, 24, 24, 0.5);
  --variable-collection-white: rgba(255, 255, 255, 1);
  --variable-collection-primary-yellow-50: rgba(249, 238, 225, 0.5);
  --variable-collection-primary-red-100: rgba(133, 45, 35, 1);
  --variable-collection-primary-green-100: rgba(42, 95, 64, 1);
  --text-5-font-family: 'Montserrat-Medium', Helvetica;
  --text-5-font-weight: 500;
  --text-5-font-size: 15px;
  --text-5-letter-spacing: 0px;
  --text-5-line-height: 100%;
  --text-5-font-style: normal;
  --other-menu-m-font-family: 'Roboto-Medium', Helvetica;
  --other-menu-m-font-weight: 500;
  --other-menu-m-font-size: 16px;
  --other-menu-m-letter-spacing: 0px;
  --other-menu-m-line-height: 100%;
  --other-menu-m-font-style: normal;
  --body-XS-font-family: 'Roboto-Regular', Helvetica;
  --body-XS-font-weight: 400;
  --body-XS-font-size: 12px;
  --body-XS-letter-spacing: 0px;
  --body-XS-line-height: 139.9999976158142%;
  --body-XS-font-style: normal;
  --variable-collection-primary-yellow-100: rgba(249, 238, 225, 1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'CENTRASONE';
  src: url('../public/fonts/centrasone.ttf') format('truetype');
}


.home_down_banner_section {
  padding-bottom: 96px;
  width: 100%;
}

.home_down_banner_section_img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

button:hover {
  cursor: pointer;
}

@media (320px <= width <= 600px) {
  .home_down_banner_section {
    padding-bottom: 48px;
  }
}

.rotate {
  transform: rotateZ(180deg);
}

